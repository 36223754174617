<template>
  <div>
    <header
      id="header-modo-ead"
      class="fd-app-mode-ead-header"
    >
      <div
        id="divBannerMeusCursos"
        class="fd-app-mode-ead-header-bg"
        :style="{ backgroundImage: 'url(' + $store.state.fastPlataforma.banner_plataforma + ')', backgroundColor: '#000' }"
      />
      <div class="fd-app-mode-ead-header-overlay">
        <div class="container">
          <div class="row justify-content-between align-items-center">
            <div class="col-sm-12 col-md-12 col-lg-8">
              <h1
                :style="{ color: $store.state.fastPersonalizacao.titulo_banner_color ? $store.state.fastPersonalizacao.titulo_banner_color : '#fff', textShadow: '1px 1px #000' }"
              >
                {{ $store.state.fastPersonalizacao.titulo_banner ? $store.state.fastPersonalizacao.titulo_banner : '' }}
              </h1>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-4">
              <mural-avisos />
            </div>
          </div>
        </div>
      </div>
    </header>
    <!-- fd-wrap -->
    <div class="fd-app-wrap">
      <div class="container">
        <!-- breadcrumb -->
        <nav>
          <ul class="fd-app-breadcrumb fast-plataforma-iuea-hide">
            <li class="fd-app-breadcrumb-item">
              <a
                href="/minhas-plataformas"
                class="aluno_font_color"
                @click.prevent="$router.push('/minhas-plataformas')"
              >Minhas Plataformas</a>
            </li>
            <li class="fd-app-breadcrumb-item">
              <a
                :href="'/plataforma/' + $route.params.id_plataforma"
                class="aluno_font_color"
                @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma)"
              >Plataforma {{ $store.state.fastPlataforma.nome_plataforma }}</a>
            </li>
            <li class="fd-app-breadcrumb-item">
              <a
                class="aluno_font_color"
                :href="'/plataforma/' + $route.params.id_plataforma + '/aluno'"
                @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/aluno')"
              >Modo aluno</a>
            </li>
            <li class="fd-app-breadcrumb-item active">
              <a href="#">Atendimento</a>
            </li>
          </ul>
          <ul class="fd-app-breadcrumb d-none fast-menu-lives-aluno-iuea">
            <li class="fd-app-breadcrumb-item">
              <a
                class="aluno_font_color"
                :href="'/plataforma/' + $route.params.id_plataforma + '/aluno'"
                @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/aluno')"
              >Menu principal</a>
            </li>
            <li class="fd-app-breadcrumb-item active">
              <a
                class="aluno_font_color"
                href="#"
              >Atendimento</a>
            </li>
          </ul>
        </nav>
        <!-- /breadcrumb -->
      </div>

      <div class="fd-mode-ead-content">
        <div class="container">
          <div class="row">
            <transition name="slide-fade">
              <component
                :is="HomeInternoAlunoMenuLateral"
                v-if="!isMobile()"
              />
            </transition>
            <div
              id="body_conteudo_modo_ead"
              class="col-sm-12 col-md-12 col-lg-11 fd-mode-ead-content-body"
            >
              <div class="fd-mode-ead-content mt-0 fast-iuea-ml-20">
                <div class="container">
                  <div class="row justify-content-center">
                    <div class="col-sm-12 col-md-12 col-lg-12">
                      <div class="row">
                        <div class="col-sm-12 col-md-12 col-lg-12">
                          <!-- fd-app-welcome -->
                          <section class="fd-app-welcome text-left fast-plataforma-iuea-hide">
                            <h1 class="aluno_font_color">
                              Atendimento da
                              <span>plataforma</span>
                            </h1>
                            <p
                              class="aluno_font_color"
                            >
                              escolha como você deseja entrar em contato conosco
                            </p>
                            <div class="fd-welcome-form-effect fast-plataforma-iuea-hide">
                              <img
                                :src="require('@/assets/images/form-effect-01.png')"
                                class="mt-2"
                              >
                            </div>
                          </section>
                          <section
                            class="fd-app-welcome text-left d-none fast-menu-lives-aluno-iuea mt-4 mb-4 ml-4"
                          >
                            <h1 class="aluno_font_color">
                              Atendimento da plataforma
                            </h1>
                          </section>
                          <!-- /fd-app-welcome -->
                          <section class="fd-app-meus-cursos">
                            <div class="fd-app-meus-cursos-list">
                              <div
                                id="div-atendimento-modoead"
                                class="row justify-content-around"
                              >
                                <!-- fd-app-atendimento -->
                                <section class="fd-app-atendimento">
                                  <div
                                    v-if="$store.state.fastPersonalizacao.id_plataforma"
                                    class="fd-app-atendimento-contato"
                                  >
                                    <div class="table-responsive">
                                      <table class="table fd-app-atendimento-contato-table">
                                        <thead>
                                          <tr>
                                            <th>Assunto</th>
                                            <th class="aluno_font_color">
                                              Contato
                                            </th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          <tr v-if="$store.state.fastPersonalizacao.email_suporte">
                                            <td>
                                              <img
                                                v-lazy="require('@/assets/images/app/table-icon-comercial.png')"
                                              >
                                              <span>Suporte</span>
                                            </td>
                                            <td class="aluno_font_color">
                                              {{ $store.state.fastPersonalizacao.email_suporte }}
                                              <br>
                                              <a
                                                v-if="$store.state.fastPersonalizacao.telefone_suporte"
                                                class="btn btn-hollow-phone btn-lg aluno_font_color"
                                                href="#"
                                              >
                                                <b-icon-telephone-inbound />
                                                {{ $store.state.fastPersonalizacao.telefone_suporte }}
                                              </a>
                                            </td>
                                          </tr>

                                          <tr
                                            v-if="$store.state.fastPersonalizacao.email_financeiro"
                                          >
                                            <td>
                                              <img
                                                v-lazy="require('@/assets/images/app/table-icon-cliente.png')"
                                              >
                                              <span>Financeiro</span>
                                            </td>
                                            <td class="aluno_font_color">
                                              {{ $store.state.fastPersonalizacao.email_financeiro }}
                                              <br>
                                              <a
                                                v-if="$store.state.fastPersonalizacao.telefone_financeiro"
                                                class="btn btn-hollow-phone btn-lg aluno_font_color"
                                                href="#"
                                              >
                                                <b-icon-telephone-inbound />
                                                {{ $store.state.fastPersonalizacao.telefone_financeiro }}
                                              </a>
                                            </td>
                                          </tr>

                                          <tr
                                            v-if="$store.state.fastPersonalizacao.email_pedagogico"
                                          >
                                            <td>
                                              <img
                                                v-lazy="require('@/assets/images/app/table-icon-cliente.png')"
                                              >
                                              <span>Pedagógico</span>
                                            </td>
                                            <td class="aluno_font_color">
                                              {{ $store.state.fastPersonalizacao.email_pedagogico }}
                                              <br>
                                              <a
                                                v-if="$store.state.fastPersonalizacao.telefone_pedagogico"
                                                class="btn btn-hollow-phone btn-lg aluno_font_color"
                                                href="#"
                                              >
                                                <b-icon-telephone-inbound />
                                                {{ $store.state.fastPersonalizacao.telefone_pedagogico }}
                                              </a>
                                            </td>
                                          </tr>

                                          <tr
                                            v-if="$store.state.fastPersonalizacao.email_secretaria"
                                          >
                                            <td>
                                              <img
                                                v-lazy="require('@/assets/images/app/table-icon-cliente.png')"
                                              >
                                              <span>Secretaria</span>
                                            </td>
                                            <td class="aluno_font_color">
                                              {{ $store.state.fastPersonalizacao.email_secretaria }}
                                              <br>
                                              <a
                                                v-if="$store.state.fastPersonalizacao.telefone_secretaria"
                                                class="btn btn-hollow-phone btn-lg aluno_font_color"
                                                href="#"
                                              >
                                                <b-icon-telephone-inbound />
                                                {{ $store.state.fastPersonalizacao.telefone_secretaria }}
                                              </a>
                                            </td>
                                          </tr>
                                          <tr
                                            v-if="$store.state.fastPersonalizacao.email_atendimento"
                                          >
                                            <td>
                                              <img
                                                v-lazy="require('@/assets/images/app/table-icon-cliente.png')"
                                                class="fast-menu-lives-aluno-fast"
                                              >
                                              <span class="fast-menu-lives-aluno-fast">Atendimento</span>
                                            </td>
                                            <td
                                              class="aluno_font_color"
                                            >
                                              {{ $store.state.fastPersonalizacao.email_atendimento }}
                                            </td>
                                          </tr>
                                          <tr
                                            v-if="$store.state.fastPersonalizacao.email_atendimento_professor"
                                          >
                                            <td>
                                              <img
                                                v-lazy="require('@/assets/images/app/table-icon-cliente.png')"
                                              >
                                              <span>Professor</span>
                                            </td>
                                            <td
                                              class="aluno_font_color"
                                            >
                                              {{ $store.state.fastPersonalizacao.email_atendimento_professor }}
                                            </td>
                                          </tr>
                                          <tr
                                            v-if="$store.state.fastPersonalizacao.email_atendimento_rh"
                                          >
                                            <td>
                                              <img
                                                v-lazy="require('@/assets/images/app/table-icon-cliente.png')"
                                              >
                                              <span>RH</span>
                                            </td>
                                            <td
                                              class="aluno_font_color"
                                            >
                                              {{ $store.state.fastPersonalizacao.email_atendimento_rh }}
                                            </td>
                                          </tr>
                                          <tr
                                            v-if="$store.state.fastPersonalizacao.link_chamado"
                                          >
                                            <td>
                                              <img
                                                v-lazy="require('@/assets/images/app/table-icon-cliente.png')"
                                              >
                                              <span>Chamado</span>
                                            </td>
                                            <td
                                              class="aluno_font_color"
                                            >
                                              <a
                                                :href="$store.state.fastPersonalizacao.link_chamado"
                                                target="_blank"
                                              >{{ $store.state.fastPersonalizacao.link_chamado }}</a>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                    <div class="fd-app-atendimento-contato-list">
                                      <a
                                        v-if="$store.state.fastPersonalizacao.chat_on_line"
                                        id="atendChatOnline"
                                        class="btn btn-chat-online btn-lg"
                                        href="#"
                                      >
                                        <b-icon-chat-quote />
                                        {{ $store.state.fastPersonalizacao.chat_on_line }}
                                      </a>
                                      <a
                                        v-if="$store.state.fastPersonalizacao.whatsapp"
                                        id="atendWhats"
                                        class="btn btn-hollow-whatsapp btn-lg"
                                        target="_blank"
                                        :href="'https://api.whatsapp.com/send?phone=+' + $store.state.fastPersonalizacao.whatsapp.replace('(', '').replace(')', '').replace(' ', '') + '&amp;text='"
                                      >
                                        <b-icon-chat-quote />
                                        {{ $store.state.fastPersonalizacao.whatsapp }}
                                      </a>
                                      <a
                                        v-if="$store.state.fastPersonalizacao.telefone"
                                        id="atendTelefone"
                                        class="btn btn-hollow-phone btn-lg"
                                        href="#"
                                      >
                                        <b-icon-telephone-inbound />
                                        {{ $store.state.fastPersonalizacao.telefone }}
                                      </a>
                                    </div>
                                  </div>

                                  <div
                                    v-if="fastPlataformaPersonalizacaoAdmin.id_plataforma"
                                    class="fd-app-atendimento-contato fast-plataforma-iuea-hide d-none"
                                  >
                                    <h3>
                                      Atendimento
                                      <span>FastEAD</span>
                                    </h3>
                                    <p>Contate-nos por e-mail, via chat ou whatsapp</p>
                                    <table class="table fd-app-atendimento-contato-table">
                                      <thead>
                                        <tr>
                                          <th>Assunto</th>
                                          <th>Contato</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr
                                          v-if="fastPlataformaPersonalizacaoAdmin.email_atendimento"
                                        >
                                          <td>
                                            <img
                                              v-lazy="require('@/assets/images/app/table-icon-cliente.png')"
                                            >
                                            <span>Cliente</span>
                                          </td>
                                          <td
                                            id="atendEmailCliente"
                                          >
                                            {{ fastPlataformaPersonalizacaoAdmin.email_atendimento }}
                                          </td>
                                        </tr>
                                        <tr
                                          v-if="fastPlataformaPersonalizacaoAdmin.email_atendimento_professor"
                                        >
                                          <td>
                                            <img
                                              v-lazy="require('@/assets/images/app/table-icon-professor.png')"
                                            >
                                            <span>Professor</span>
                                          </td>
                                          <td
                                            id="atendEmailProf"
                                          >
                                            {{ fastPlataformaPersonalizacaoAdmin.email_atendimento_professor }}
                                          </td>
                                        </tr>
                                        <tr
                                          v-if="fastPlataformaPersonalizacaoAdmin.email_atendimento_rh"
                                        >
                                          <td>
                                            <img
                                              v-lazy="require('@/assets/images/app/table-icon-comercial.png')"
                                            >
                                            <span>RH</span>
                                          </td>
                                          <td
                                            id="atendEmailRh"
                                          >
                                            {{ fastPlataformaPersonalizacaoAdmin.email_atendimento_rh }}
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                    <div class="fd-app-atendimento-contato-list">
                                      <a
                                        v-if="fastPlataformaPersonalizacaoAdmin.chat_on_line"
                                        id="atendChatOnline"
                                        class="btn btn-chat-online btn-lg"
                                        href="#"
                                      >
                                        <b-icon-chat-quote />
                                        {{ fastPlataformaPersonalizacaoAdmin.chat_on_line }}
                                      </a>
                                      <a
                                        v-if="fastPlataformaPersonalizacaoAdmin.whatsapp"
                                        id="atendWhats"
                                        class="btn btn-hollow-whatsapp btn-lg"
                                        :href="'https://api.whatsapp.com/send?phone=+' + fastPlataformaPersonalizacaoAdmin.whatsapp.replace('(', '').replace(')', '').replace(' ', '') + '&amp;text='"
                                      >
                                        <b-icon-chat-quote />
                                        {{ fastPlataformaPersonalizacaoAdmin.whatsapp }}
                                      </a>
                                      <a
                                        v-if="fastPlataformaPersonalizacaoAdmin.telefone"
                                        id="atendTelefone"
                                        class="btn btn-hollow-phone btn-lg"
                                        href="#"
                                      >
                                        <b-icon-telephone-inbound />
                                        {{ fastPlataformaPersonalizacaoAdmin.telefone }}
                                      </a>
                                    </div>
                                  </div>
                                </section>
                                <!-- /fd-app-atendimento -->
                              </div>
                            </div>
                          </section>
                          <div class="col-12 mt-4">
                            <a
                              class="btn btn-red-hollow fast-iuea-ml-20"
                              :href="'/plataforma/' + $route.params.id_plataforma + '/aluno'"
                              @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/aluno')"
                            >
                              <b-icon-arrow-return-left /> Voltar
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /fd-wrap -->
  </div>
</template>

<script>
import { settings } from "@/config";
import methods from "@/methods";
import muralAvisos from "../components/MuralAvisos";

export default {
  name: "HomeInternoAlunoAtendimento",
  components: {
    muralAvisos,
  },
  mixins: [methods],
  data: function () {
    return {
      fastTemplate: settings.fastTemplate,
      // Personalização
      fastPlataformaPersonalizacaoAdmin: [],
    };
  },
  computed: {
    HomeInternoAlunoMenuLateral() {
      return () =>
        import(
          `@/templates/${this.fastTemplate}/components/HomeInternoAlunoMenuLateral.vue`
        );
    },
  },
  mounted: function () {
    // Captura ID da plataforma pela URL
    if (this.$route.params.id_plataforma) {
      this.getFastDependecias(this.$route.params.id_plataforma, 'aluno')
        .then(() => {
          this.$store.state.fastCarregando = false;
        })
        .catch((e) => {
          this.exibeToasty(e, "error");
          this.$store.state.fastCarregando = false;
        });
    }
  },
  methods: {
    async getPlataformaPersonalizacaoAdmin() {
      try {
        let resp = await fetch(
          settings.endApiFastEad +
            "api/fast_personalizacao/lista_atendimento?id_plataforma=" +
            this.$route.params.id_plataforma +
            "&nome_plataforma=admin",
          this.fastAjaxOptions("GET")
        );
        let json = await resp.json();
        let obj = Array.from(json);
        if (obj.length) {
          this.fastPlataformaPersonalizacaoAdmin = obj[0];
        }
        console.log(
          "getPlataformaPersonalizacaoAdmin",
          this.fastPlataformaPersonalizacaoAdmin
        );
        this.getPlataformaPersonalizacao();
      } catch (e) {
        console.log("Erro", e);
      }
    },
  },
};
</script>

<style scope>
#div-atendimento-modoead {
  display: block;
}
.fd-app-atendimento-contato-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
</style>
